<template>
<footer class="footer">
  <div class="footer__content shell">
    <a href="#" class="footer__logo">
      <img
        :src="logo"
        :srcset="logoSrcSet"
        :width="width"
        :height="height"
        alt="logo">
    </a>

    <p class="footer__copyright">
      <img
        src="../assets/images/icons/ico-marker-alt.svg"
        width="25"
        height="30"
        alt="pitc-logo">

      <span>&copy; {{ $t('components.footer.copyright') }}</span>
    </p><!-- /.footer__copyright -->
  </div><!-- /.footer__content -->

  <div class="footer__bar shell">
    <nav class="footer__nav">
      <ul>
        <li>
          <a href="/#/legal">
            {{ $t('components.footer.legal') }}
          </a>
        </li>
        <li>
          <a
            href="/pdf/cgu_20181024_pitc.pdf"
            target="_blank">
            {{ $t('components.footer.tos') }}
          </a>
        </li>
      </ul>
    </nav><!-- /.footer__nav -->
  </div><!-- /.footer__bar -->
</footer><!-- /.footer -->
</template>

<script>

export default {
  props: {
    logo: {
      type: String,
      required: true,
    },
    logoSrcSet: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
footer {
  position:relative;
}
</style>
