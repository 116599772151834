<template>
  <section class="article__section" :id="id">
    <h3>
      <img v-if="picture"
        :src="picture"
        :width="width"
        :height="height"
        :alt="`icon-${title}`">
      {{ title }}
    </h3>
    <div v-html="content"></div>
  </section>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    picture: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: false,
      default: 0,
    },
    height: {
      type: Number,
      required: false,
      default: 0,
    },
  },
};
</script>
