<template>
  <header class="section__head section__head--medium-margin u-append-line-small">
    <a
      href="#"
      @click.prevent="$emit('click')"
      class="btn-back btn-back--primary btn-back--pulled">
      <img
        src="../assets/images/icons/ico-arrow-left.svg"
        width="8"
        height="13"
        alt="Back button">
        {{ title }}
    </a>
  </header>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
